/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import DataTable from '../../../modules/dataTable/DataTable/DataTable';
import { columns } from '../constants/columns';
import { SeedSourcesAutoTreatContext } from '../contexts/SeedSourcesAutoTreatContext';
import { MachinesContext } from '../../../modules/machinesAdminManagement/contexts/MachinesContext';
import SeedSourceAutoTreatFilterBar from '../components/SeedSourcesAutoTreatFilterBar';
import { SeedSourceAutoTreat } from '../model';

const SeedSourcesAutoTreatList: React.FunctionComponent = () => {
  const {
    fetchSeedVarietyOptionsAutoTreat,
    seedSources,
    activeMachine,
    setActiveMachine,
    setDetailPage,
    setActiveSeedSourceAutoTreat,
  } = React.useContext(SeedSourcesAutoTreatContext);

  const [tableFilters, setTableFilters] = React.useState({
    seedSourceName: '',
    seedSourceLocation: '',
    seedSourceVariety: '',
  });

  const [fitlerBarActive, setFitlerBarActive] = React.useState(true);
  const { autoTreatMachines, machinesLoading } = React.useContext(MachinesContext);

  const handleFetchSeedOptions = (value) => {
    if (value) {
      fetchSeedVarietyOptionsAutoTreat(value);
    } else {
      fetchSeedVarietyOptionsAutoTreat(localStorage.getItem('seedSourcesAutoTreatMachine'));
    }
  };

  const filterSeedSources = () => {
    if (tableFilters.seedSourceName || tableFilters.seedSourceLocation || tableFilters.seedSourceVariety) {
      const nameFilteredSeedSources = seedSources.filter((seedSource) =>
        seedSource.seed_source?.toLowerCase().includes(tableFilters.seedSourceName?.toLowerCase()),
      );

      const locationFilteredSeedSources = nameFilteredSeedSources.filter((seedSource) =>
        seedSource.seed_source_location?.toLowerCase().includes(tableFilters.seedSourceLocation?.toLowerCase()),
      );

      const varietyFilteredSeedSources = locationFilteredSeedSources.filter((seedSource) =>
        seedSource.variety?.toLowerCase().includes(tableFilters.seedSourceVariety?.toLowerCase()),
      );

      return varietyFilteredSeedSources;
    }

    return seedSources;
  };

  return (
    <>
      <Grid justifyContent="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Seed Source Management
          </Typography>
          <br />
        </Grid>
      </Grid>
      <br />
      {fitlerBarActive && (
        <Grid justifyContent="space-between" container>
          <Grid item md={12}>
            <SeedSourceAutoTreatFilterBar
              machineChoices={autoTreatMachines}
              machineChoicesLoading={machinesLoading}
              tableFilters={tableFilters}
              setTableFilters={setTableFilters}
              fetchOptions={handleFetchSeedOptions}
            />
            <br />
          </Grid>
        </Grid>
      )}
      <br />
      <>
        <Card>
          <CardContent>
            <Grid justifyContent="space-between" container spacing={0}>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h1" component="h1" style={{ fontSize: '22px' }}>
                  Seed Source List
                </Typography>
              </Grid>
            </Grid>
            {activeMachine && activeMachine?.value !== 'Select A Machine' ? (
              <DataTable
                noHeader
                columns={columns}
                data={filterSeedSources()}
                defaultSortField="liquid_seedSources_id"
                defaultSortAsc={false}
                striped
                onRowClicked={(row: SeedSourceAutoTreat) => {
                  setActiveSeedSourceAutoTreat(row as any);
                  setDetailPage(true);
                  setFitlerBarActive(false);
                  setActiveMachine(activeMachine);
                }}
                highlightOnHover
                pointerOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
              />
            ) : (
              <Grid container alignItems="center" justifyContent="center">
                <Typography variant="h2" component="h2" style={{ marginTop: 15 }}>
                  Please select a machine to view details.
                </Typography>
              </Grid>
            )}
          </CardContent>
          <br />
        </Card>
      </>
    </>
  );
};

export default SeedSourcesAutoTreatList;
