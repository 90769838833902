import React from 'react';
import axios from '../../../modules/utils/axios.utils';
import { AutoTreatTransaction } from '../model';

interface SeedVarietiesContextInterface {
  errorMessage?: string;
  exportEmail?: string;
  exportTransactions?: (
    exportEmail: string,
    includeHeader: boolean,
    includeProducts: boolean,
    localTime: any,
    utcOffset: any,
    filterParams: string,
    filterType: string,
    yearlyOverview?: boolean,
    combineMachineTotals?: boolean,
  ) => Promise<void>;
  fetchPaginatedSeedVarieties?: (url?: string) => Promise<void>;
  fetchSeedVarieties?: (combined: boolean, filterParams?: string) => Promise<void>;
  seedVarieties?: AutoTreatTransaction[];
  seedVarietiesLoading?: boolean;
  seedVarietyMetrics?: Record<string, string>;
}

const SeedVarietiesContext = React.createContext<SeedVarietiesContextInterface>({});

const SeedVarietiesContextConsumer = SeedVarietiesContext.Consumer;
const SeedVarietiesContextProvider: React.FC = ({ children }) => {
  const [seedVarieties, setSeedVarieties] = React.useState<AutoTreatTransaction[]>([]);
  const [seedVarietyMetrics, setSeedVarietyMetrics] = React.useState<Record<string, string>>({});
  const [seedVarietiesLoading, setSeedVarietiesLoading] = React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [hasYearlyOverview, setHasYearlyOverview] = React.useState<boolean>(false);
  const [exportEmail] = React.useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const fetchSeedVarieties = async (combined: boolean, filterParams = '') => {
    setSeedVarietiesLoading(true);
    setErrorMessage('');

    const cachedMachines = JSON.parse(localStorage.getItem('machines') || '[]');
    // for simplicity we are passing the timePeriod and dates through session storage
    const cachedTimePeriod = localStorage.getItem('timePeriod') || '';
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    console.log('');
    console.log(`cachedTimePeriod: ${cachedTimePeriod}`);
    console.log('');
  
    let timeParams = {}

    if (cachedStartDate && cachedEndDate) {
      timeParams = cachedTimePeriod !== 'Custom'
        ? { time_period: cachedTimePeriod || '' }
        : {
            dateandtimeordercomplete_after: cachedStartDate || '',
            dateandtimeordercomplete_before: cachedEndDate || '',
          };
    } else {
      timeParams = { time_period: cachedTimePeriod || '' };
    }

    const params = new URLSearchParams({
      machine: cachedMachines.join(),
      ...Object.fromEntries(new URLSearchParams(filterParams || '')),
      ...timeParams,
    }).toString();

    axios
      .get(`api/autotreat/analytics/transactions/seed_varieties${combined ? '/combined' : ''}/?${params}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response: any) => {
        setHasYearlyOverview(response.data.yearly_overview);
        setSeedVarieties(response.data.results);
        setSeedVarietiesLoading(false);
        setSeedVarietyMetrics({
          actual_units_with_uom: response.data.total_units_delivered,
          total_records: response.data.count,
          next_page: response.data.next,
          previous_page: response.data.previous,
        });
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setSeedVarieties([]);
        setSeedVarietiesLoading(false);
      });
  };

  const fetchPaginatedSeedVarieties = async (url = '') => {
    setSeedVarietiesLoading(true);
    setErrorMessage('');

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response: any) => {
        setSeedVarieties(response.data.results);
        setSeedVarietiesLoading(false);
        setHasYearlyOverview(response.data.yearly_overview);
        setSeedVarietyMetrics({
          actual_units_with_uom: response.data.total_units_delivered,
          total_records: response.data.count,
          next_page: response.data.next,
          previous_page: response.data.previous,
        });
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setSeedVarieties([]);
        setSeedVarietiesLoading(false);
      });
  };

  const exportTransactions = async (
    exportEmail,
    includeHeader,
    includeProducts,
    localTime,
    utcOffset,
    filterParams,
    filterType,
    yearlyOverview?,
    combineMachineTotals?,
  ) => {
    setSeedVarietiesLoading(true);

    const cachedMachines = JSON.parse(localStorage.getItem('machines') || '[]');
    // for simplicity we are passing the timePeriod and dates through session storage
    const time_period = localStorage.getItem('timePeriod') || '';
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    // Parse filterParams into an object
    const filterParamsObj = Object.fromEntries(new URLSearchParams(filterParams));

    // Add date and time fields if timePeriod is 'Custom'
    if (time_period === 'Custom' && cachedStartDate && cachedEndDate) {
      filterParamsObj['dateandtimeordercomplete_after'] = cachedStartDate || '';
      filterParamsObj['dateandtimeordercomplete_before'] = cachedEndDate || '';
    } 

    // Rebuild filterParams from the updated object
    filterParams = new URLSearchParams(filterParamsObj).toString();

    console.log('');
    console.log(`filterParams: ${filterParams}`);
    console.log('');

    axios
      .post<any, any>(
        `api/autotreat/analytics/exports/seed_varieties_summary`,
        {
          cachedEndDate,
          cachedMachines,
          cachedStartDate,
          exportEmail,
          includeHeader,
          includeProducts,
          localTime,
          utcOffset,
          filterParams,
          time_period,
          filterType,
          combineMachineTotals,
          yearlyOverview,
        },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        setSnackbarOpen(true);
        setSeedVarietiesLoading(false);
      })
      .catch((errorMessage) => {
        setSnackbarOpen(false);
        setSeedVarietiesLoading(false);
        setErrorMessage(errorMessage.message);
        setSnackbarOpen(true);
      });
  };

  return (
    <SeedVarietiesContext.Provider
      value={{
        errorMessage,
        exportEmail,
        exportTransactions,
        fetchPaginatedSeedVarieties,
        fetchSeedVarieties,
        seedVarieties,
        seedVarietiesLoading,
        seedVarietyMetrics,
      }}
    >
      {children}
    </SeedVarietiesContext.Provider>
  );
};

export { SeedVarietiesContextProvider, SeedVarietiesContextConsumer, SeedVarietiesContext };
