import React from 'react';
import { Button, Grid, GridSize, CircularProgress, Typography } from '@mui/material';
import { SelectOption } from '../../../modules/shared/model';
import AutocompleteFilter from '../../../modules/shared/form/AutocompleteFilter';
import SelectInput from '../../../modules/shared/form/SelectInput';
import FormInput from '../../../modules/shared/form/FormInput';
import { SeedSourcesAutoTreatContext } from '../contexts/SeedSourcesAutoTreatContext';
import PaginationBanner from '../../../modules/shared/machineManagement/PaginationBanner';
import convertToLocalTime from '../../../modules/shared/utils/convertToLocalTime';

export interface FilterProps {
  label: string;
  pluralLabel: string;
  selector: string;
  width: boolean | GridSize;
  type: string;
  optionSelector: string;
  options?: SelectOption[];
}

interface SeedSourcesAutoTreatFilterBarProps {
  filters?: FilterProps[];
  filterValues?: any;
  machineChoices?: any;
  machineChoicesLoading?: boolean;
  filterChoices?: any;
  tableFilters?: any;
  setTableFilters?: any;
  fetchOptions?: any;
}

const SeedSourcesAutoTreatFilterBar: React.FC<SeedSourcesAutoTreatFilterBarProps> = (props) => {
  const {
    filters = [],
    filterValues,
    filterChoices,
    machineChoices,
    machineChoicesLoading,
    tableFilters,
    setTableFilters,
    fetchOptions,
  } = props;

  const [temporaryValues, setTemporaryValues] = React.useState<Record<string, any[]>>(filterValues);
  const [onPremPage, setOnPremPage] = React.useState<number>(0);

  const {
    seedSources,
    fetchSeedSourcesAutoTreat,
    fetchCachedSeedSourcesAutoTreat,
    seedSourcesLoading,
    seedSourcesRefreshedAt,
    activeMachine,
    setActiveMachine,
    handleGenericFilterChange,
    genericFilter,
  } = React.useContext(SeedSourcesAutoTreatContext);

  React.useEffect(() => {
    if (seedSources.length === 0 && localStorage.getItem('seedSourcesAutoTreatMachine')) {
      fetchCachedSeedSourcesAutoTreat(localStorage.getItem('seedSourcesAutoTreatMachine'));
      fetchOptions();
    }
  }, []);

  const handleMachineChange = (value: string) => {
    localStorage.setItem('seedSourcesAutoTreatMachine', value);
    fetchCachedSeedSourcesAutoTreat(value);
    setActiveMachine(machineChoices.filter((choice) => choice?.value === value)?.[0]);
    fetchOptions(value);
    // fetch all of the seed varieties here
  };

  const defaultMachineChoice = () => {
    if (machineChoicesLoading) {
      return { label: 'Loading...', value: 'Select A Machine' };
    }

    return { label: 'Select A Machine', value: 'Select A Machine' };
  };

  const handleCancel = () => {
    window.location.reload();
  };

  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item md={2} xs={6}>
            <SelectInput
              value={activeMachine?.value}
              groupedChoices={{
                'Online Machines': machineChoices.filter(
                  (machine) => machine.subscription_active && machine.online_status === 'online',
                ),
                'Offline Machines': machineChoices.filter(
                  (machine) => machine.subscription_active && machine.online_status === 'offline',
                ),
                'Expired Machines': machineChoices.filter((machine) => machine.subscription_active === false),
              }}
              defaultChoice={defaultMachineChoice()}
              onChange={handleMachineChange}
              label="Machine"
              customPadding="8px 27px 9px 15px"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={genericFilter}
              onChange={(value) => handleGenericFilterChange(value)}
              label="Generic Filter"
            />
          </Grid>
          {filters.map((filter) => (
            <React.Fragment key={filter.label}>
              <Grid item>
                <AutocompleteFilter
                  label={filter.label}
                  loading={false}
                  pluralLabel={filter.pluralLabel}
                  choices={filter?.options || filterChoices[filter?.optionSelector] || []}
                  setValue={(value) => {
                    setTemporaryValues({ ...temporaryValues, [filter?.selector]: value });
                  }}
                  value={temporaryValues[filter?.selector] as string[]}
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              disabled={seedSourcesLoading || activeMachine?.value === 'Select A Machine'}
              onClick={() => {
                fetchSeedSourcesAutoTreat(activeMachine?.value);
                fetchOptions();
              }}
            >
              {seedSourcesRefreshedAt ? 'Load' : 'Load'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19, marginLeft: 13 }}
              disableElevation
              disabled={!seedSourcesLoading}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
            {seedSourcesLoading ? (
              <span style={{ marginLeft: 20, position: 'relative', top: 19 }}>
                <CircularProgress style={{ width: 30, height: 30 }} />
              </span>
            ) : (
              <span style={{ marginLeft: 7, position: 'relative', top: 10 }}>
                Last Loaded: {convertToLocalTime(seedSourcesRefreshedAt)}
              </span>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {seedSources?.length === 500 && (
            <PaginationBanner
              totalRecords={seedSources?.length}
              currentPage={onPremPage}
              onPageChange={(newPage) => setOnPremPage(newPage)}
            />
          )}
        </Grid>
      </Grid>

      <br />
      <Typography variant="h2" component="h2" style={{ marginTop: 10, marginBottom: -18, marginLeft: 5 }}>
        Live Filters
      </Typography>
      <Grid item xs={12} style={{ marginTop: 20 }}>
        <Grid container spacing={1} alignItems="flex-start">
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.seedSourceName}
              onChange={(value) => setTableFilters({ ...tableFilters, seedSourceName: value })}
              label="Name"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.seedSourceLocation}
              onChange={(value) => setTableFilters({ ...tableFilters, seedSourceLocation: value })}
              label="Location"
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <FormInput
              value={tableFilters?.seedSourceVariety}
              onChange={(value) => setTableFilters({ ...tableFilters, seedSourceVariety: value })}
              label="Variety"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ color: '#fff', marginTop: 19 }}
              disableElevation
              onClick={() =>
                setTableFilters({ ...tableFilters, seedSourceName: '', seedSourceLocation: '', seedSourceVariety: '' })
              }
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SeedSourcesAutoTreatFilterBar;
