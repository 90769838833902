import React from 'react';
import axios from '../../../modules/utils/axios.utils';
import { AutoTreatCustomerFiltersInterface } from '../model';

interface AutoTreatFiltersContextInterface {
  errorMessage?: string;
  fetchFilterOptions?: (filterParams?: string) => Promise<void>;
  filterOptions?: Record<string, string[]>;
  filterOptionsLoading?: boolean;
  formatTransactionFilterValues?: (
    machines: string[],
    customFilters: AutoTreatCustomerFiltersInterface,
  ) => string;
}

const AutoTreatFiltersContext = React.createContext<AutoTreatFiltersContextInterface>({});

const AutoTreatFiltersContextConsumer = AutoTreatFiltersContext.Consumer;
const AutoTreatFiltersContextProvider: React.FC = ({ children }) => {
  const [filterOptions, setFilterOptions] = React.useState<Record<string, string[]>>({});
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [filterOptionsLoading, setFilterOptionsLoading] = React.useState<boolean>(false);

  const fetchFilterOptions = async (filterParams = '') => {
    setErrorMessage('');
    setFilterOptionsLoading(true);

    const cachedMachines = JSON.parse(localStorage.getItem('machines') || '[]');
    const cachedTimePeriod = localStorage.getItem('timePeriod') || '';
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    let timeParams = {}

    if (cachedStartDate && cachedEndDate) {
      timeParams = cachedTimePeriod !== 'Custom'
        ? { time_period: cachedTimePeriod || '' }
        : {
            dateandtimeordercomplete_after: cachedStartDate || '',
            dateandtimeordercomplete_before: cachedEndDate || '',
          };
    } else {
      timeParams = { time_period: cachedTimePeriod || '' };
    }

    // TODO Andy decide if remove date entirely or make the time period stuff work here...
    const params = new URLSearchParams({
      // dateandtimeordercomplete_after: cachedStartDate,
      // dateandtimeordercomplete_before: cachedEndDate,
      machine: cachedMachines.join(),
      ...Object.fromEntries(new URLSearchParams(filterParams)),
      // ...timeParams,
    }).toString();

    axios
      .get(`api/autotreat/analytics/filters/?${params}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response: any) => {
        setFilterOptions({
          seed_variety_name_list: response.data.seed_varieties_list,
          crop_name_list: response.data.crops_list,
          recipe_name_list: response.data.recipe_name_list,
          customer_name_list: response.data.customer_names_list,
          treatment_product_list: response.data.treatment_products_list,
        });
        setFilterOptionsLoading(false);
      })
      .catch(() => {
        setFilterOptionsLoading(false);
      });
  };

  const formatTransactionFilterValues = (
    machines: string[],
    customFilters: AutoTreatCustomerFiltersInterface,
  ) => {
    let filterValues = '';

    filterValues += ''

    if (machines) {
      filterValues += `machine=${machines?.join()}&`;
    }

    if (customFilters?.customers?.length > 0) {
      filterValues += `operation=${encodeURIComponent(customFilters.customers?.join('|~|'))}&`;
    }

    if (customFilters?.seed_variety?.length > 0) {
      filterValues += `variety=${encodeURIComponent(customFilters.seed_variety?.join('|~|'))}&`;
    }

    if (customFilters?.crops?.length > 0) {
      filterValues += `seed=${encodeURIComponent(customFilters.crops?.join('|~|'))}&`;
    }

    if (customFilters?.recipes?.length > 0) {
      filterValues += `recipe=${encodeURIComponent(customFilters.recipes?.join('|~|'))}&`;
    }

    if (customFilters?.pickupordername) {
      filterValues += `pickupordername=${encodeURIComponent(customFilters.pickupordername)}&`;
    }

    if (customFilters?.filterChecked) {
      filterValues += `includeHeader=${encodeURIComponent(customFilters.filterChecked)}&`;
    }

    if (customFilters?.productsChecked) {
      filterValues += `includeProducts=${encodeURIComponent(customFilters.productsChecked)}&`;
    }

    if (customFilters?.treatment_products?.length > 0) {
      filterValues += `liquidname=${encodeURIComponent(customFilters.treatment_products.join('|~|'))}&`;
    }

    return filterValues;
  };

  return (
    <AutoTreatFiltersContext.Provider
      value={{
        errorMessage,
        fetchFilterOptions,
        filterOptions,
        formatTransactionFilterValues,
        filterOptionsLoading,
      }}
    >
      {children}
    </AutoTreatFiltersContext.Provider>
  );
};

export { AutoTreatFiltersContextProvider, AutoTreatFiltersContextConsumer, AutoTreatFiltersContext };
