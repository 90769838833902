import React from 'react';
import axios from '../../../modules/utils/axios.utils';
import { AutoBulkTransaction } from '../model';

interface AutoBulkTransactionsContextInterface {
  emailTransaction?: (transactionId?: string, email?: string, utcOffset?: any, onSuccess?: () => void) => Promise<void>;
  errorMessage?: string;
  exportEmail?: string;
  exportTransactions?: (
    exportEmail: string,
    includeHeader: boolean,
    includeProducts: boolean,
    localTime: any,
    utcOffset: any,
    filterParams: string,
    filterType: string,
    yearlyOverview?: boolean,
    combineMachineTotals?: boolean,
  ) => Promise<void>;
  fetchPaginatedTransactions?: (url?: string) => Promise<void>;
  fetchTransaction?: (filterId?: string) => Promise<void>;
  fetchTransactionCharts?: (filterParams?: string) => Promise<void>;
  fetchTransactions?: (filterParams?: string, page?: string, per_page?: string) => Promise<void>;
  includeProducts?: boolean;
  setIncludeProducts?: any;
  transaction?: AutoBulkTransaction;
  transactionCharts?: Record<string, any>;
  transactionChartsLoading?: boolean;
  transactionLoading?: boolean;
  transactionMetrics?: Record<string, string>;
  transactionPdfSubmitting?: boolean;
  transactions?: AutoBulkTransaction[];
  transactionsCount?: number;
  transactionsLoading?: boolean;
  totalTransactionsCount?: number;
}

const AutoBulkTransactionsContext = React.createContext<AutoBulkTransactionsContextInterface>({});

const AutoBulkTransactionsContextConsumer = AutoBulkTransactionsContext.Consumer;
const AutoBulkTransactionsContextProvider: React.FC = ({ children }) => {
  const [transactions, setTransactions] = React.useState<AutoBulkTransaction[]>([]);
  const [totalTransactionsCount, setTotalTransactionsCount] = React.useState<number>(0);
  const [transactionMetrics, setTransactionMetrics] = React.useState({});
  const [transactionsLoading, setTransactionsLoading] = React.useState<boolean>(true);

  const [transaction, setTransaction] = React.useState<AutoBulkTransaction>();
  const [transactionLoading, setTransactionLoading] = React.useState<boolean>(false);

  const [transactionCharts, setTransactionCharts] = React.useState<Record<string, any>>({});
  const [transactionChartsLoading, setTransactionChartsLoading] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [transactionPdfSubmitting, setTransactionPdfSubmitting] = React.useState(false);
  const [includeProducts, setIncludeProducts] = React.useState(false);
  const [exportEmail] = React.useState<string>('');

  const fetchTransactionCharts = async (filterParams = '', pageSize = '10') => {
    setTransactionChartsLoading(true);

    const cachedMachines = JSON.parse(localStorage.getItem('autoBulkMachines') || '[]');
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    const params = new URLSearchParams({
      work_order_start_date_after: cachedStartDate,
      work_order_start_date_before: cachedEndDate,
      machine: cachedMachines.join(),
      page_size: pageSize,
      ...Object.fromEntries(new URLSearchParams(filterParams)),
    }).toString();

    axios
      .get<any, any>(`api/autobulk/analytics/transactions/charts/?${params}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response: any) => {
        setTransactionCharts({
          orderPerDay: response.data.orders_per_day.map((order: Record<string, string>) => order.value),
          orderPerDayLabels: response.data.orders_per_day.map((order: Record<string, string>) => order.label),
          unitsDeliveredPerDay: response.data.units_delivered_per_day.map(
            (order: Record<string, string>) => order.value,
          ),
          unitsDeliveredPerDayLabels: response.data.units_delivered_per_day.map(
            (order: Record<string, string>) => order.label,
          ),
          unitsDeliveredPerWeek: response.data.units_delivered_per_week.map(
            (order: Record<string, string>) => order.value,
          ),
          unitsDeliveredPerWeekLabels: response.data.units_delivered_per_week.map(
            (order: Record<string, string>) => order.label,
          ),
          ytdUnitsDelivered: response.data.ytd_units_delivered.map((order: Record<string, string>) => order.value),
          yoyUnitsDelivered: response.data.yoy_units_delivered.map((order: Record<string, string>) => order.value),
        });
        setTransactionChartsLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setTransactionChartsLoading(false);
        setTransactionCharts({});
        setTransactionsLoading(false);
      });
  };

  const fetchTransactions = async (filterParams = '', page = '1', per_page = '10') => {
    setTransactionsLoading(true);

    const cachedMachines = JSON.parse(localStorage.getItem('autoBulkMachines') || '[]');
    const cachedTimePeriod = localStorage.getItem('timePeriod') || '';
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    let timeParams = {}

    if (cachedStartDate && cachedEndDate) {
      timeParams = cachedTimePeriod !== 'Custom'
        ? { time_period: cachedTimePeriod || '' }
        : {
          work_order_start_date_after: cachedStartDate || '',
          work_order_start_date_before: cachedEndDate || '',
          };
    } else {
      timeParams = { time_period: cachedTimePeriod || '' };
    }

    console.log('');
    console.log('timeParams');
    console.log(timeParams);
    console.log('');

    const params = new URLSearchParams({
      page: page,
      per_page: per_page,
      machine: cachedMachines.join(),
      ...Object.fromEntries(new URLSearchParams(filterParams)),
      ...timeParams,
    }).toString();

    axios
      .get<any, any>(`api/autobulk/analytics/transactions/?${params}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        setTransactions(response.data.results);
        setTotalTransactionsCount(response.data.pagination.total_count);
        setTransactionMetrics({
          total_records: response.data.count,
          next_page: response.data.next,
          previous_page: response.data.previous,
        });
        setTransactionsLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setTransactions([]);
        setTransactionMetrics({});
        setTransactionsLoading(false);
      });
  };

  const fetchPaginatedTransactions = async (url = '') => {
    setTransactionsLoading(true);

    axios
      .get<string, any>(`${url}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` },
      })
      .then((response) => {
        setTransactions(response.data.results);
        setTransactionsLoading(false);
        setTransactionMetrics({
          total_records: response.data.count,
          next_page: response.data.next,
          previous_page: response.data.previous,
        });
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setTransactions([]);
        setTransactionsLoading(false);
      });
  };

  const fetchTransaction = async (filterId?: string) => {
    setTransactionLoading(true);

    axios
      .get<any, any>(`api/autobulk/analytics/transactions/${filterId}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        },
      })
      .then((response) => {
        setTransaction(response.data.results[0]);
        setTransactionLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.Message);
        setTransactionLoading(false);
      });
  };

  const emailTransaction = async (transactionId, email, utcOffset, onSuccess?: () => void) => {
    setTransactionPdfSubmitting(true);

    axios
      .post<any, any>(
        `api/autobulk/analytics/transactions/pdf`,
        { email, transactionId, utcOffset },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        setTransactionPdfSubmitting(false);

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        setTransactionPdfSubmitting(false);
      });
  };

  const exportTransactions = async (
    exportEmail,
    includeHeader,
    includeProducts,
    localTime,
    utcOffset,
    filterParams,
    filterType,
    yearlyOverview,
    combineMachineTotals,
  ) => {
    setTransactionsLoading(true);

    const cachedMachines = JSON.parse(localStorage.getItem('AutoBulkmachines') || '[]');
    const cachedStartDate = localStorage.getItem('startDate');
    const cachedEndDate = localStorage.getItem('endDate');

    axios
      .post<any, any>(
        `api/autobulk/analytics/exports/transactions`,
        {
          cachedEndDate,
          cachedMachines,
          cachedStartDate,
          exportEmail,
          includeHeader,
          includeProducts,
          localTime,
          utcOffset,
          filterParams,
          filterType,
          yearlyOverview,
          combineMachineTotals,
        },
        { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } },
      )
      .then((response) => {
        setTransactionsLoading(false);
      })
      .catch((error) => {
        setTransactionsLoading(false);
      });
  };

  return (
    <AutoBulkTransactionsContext.Provider
      value={{
        emailTransaction,
        errorMessage,
        exportEmail,
        exportTransactions,
        fetchPaginatedTransactions,
        fetchTransaction,
        fetchTransactionCharts,
        fetchTransactions,
        includeProducts,
        setIncludeProducts,
        transaction,
        transactionCharts,
        transactionChartsLoading,
        transactionLoading,
        transactionMetrics,
        transactions,
        transactionsLoading,
        totalTransactionsCount,
      }}
    >
      {children}
    </AutoBulkTransactionsContext.Provider>
  );
};

export { AutoBulkTransactionsContextProvider, AutoBulkTransactionsContextConsumer, AutoBulkTransactionsContext };
